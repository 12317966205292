window.HBMLG = (function () {
  'use strict';

  // Public
  var module = window.HBM;

  module.logPrefixEntry = 'HBMLG';

  module.initAjaxSecured = function ($body, $elements) {
    // Load value from local storage (if possible)
    if (window.localStorage && window.localStorage.getItem('hbmlg-ajax-secured')) {
      module.ajaxSecured = JSON.parse(window.localStorage.getItem('hbmlg-ajax-secured'));
    }

    $elements.on('click', function (event) {
      event.preventDefault();

      module.handleAjaxSecured(!module.ajaxSecured, $body, $elements);
    });

    // Init buttons and body.
    module.handleAjaxSecured(module.ajaxSecured, $body, $elements);
  };

  module.handleAjaxSecured = function (newAjaxSecured, $body, $elements) {
    module.ajaxSecured = newAjaxSecured;

    if (window.localStorage) {
      window.localStorage.setItem('hbmlg-ajax-secured', JSON.stringify(module.ajaxSecured));
    }

    if (module.ajaxSecured === false) {
      $elements.addClass('active');
      $body.removeClass('ajax-secured');
    }
    if (module.ajaxSecured === true) {
      $elements.removeClass('active');
      $body.addClass('ajax-secured');
    }
  };

  module.initClipboards = function (options, optionsTooltip) {
    var defaults = $.extend({
      icon: 'fa-copy',
      class: 'btn-input-group-addon'
    }, (options || {}));

    var defaultsTooltip = $.extend({
      title: 'Inhalt kopiert!',
      placement: 'left'
    }, (optionsTooltip || {}));

    $('input[data-hbm-clipboard]').each(function () {
      var $element = $(this);

      // Handle options.
      var settings = defaults;
      try {
        settings = $.extend(settings, JSON.parse($element.attr('data-hbm-clipboard')));
      }
      catch(e) {
        module.log(e);
      }

      // Prepare content.
      var $button = $('<span class="btn ' + settings['class'] + '"><i class="fa fa-fw ' + settings['icon'] + '"></i></span>');
      var $group = $('<span class="input-group-append"></span>');

      $group.append($button);
      $element.closest('.input-group').find('.input-group-append').before($group);

      var clipboard = new ClipboardJS($button.get(0), {
        text: function () {
          return $element.val();
        }
      });

      $button.tooltip($.extend({
        trigger: 'manual'
      }, defaultsTooltip, (settings['tooltip'] || {})));

      clipboard.on('success', function (e) {
        $(e.trigger).tooltip('show');

        window.setTimeout(function () {
          $(e.trigger).tooltip('hide');
        }, 1000);
      });
    });
  };

  module.initTrackables = function ($container) {
    $container.on('change', '[data-trackable]', function (event) {
      var $element = $(this);
      var config = JSON.parse($element.attr('data-trackable'));

      var $target = $element.closest(config.target);
      if ($element.is('input[type="radio"],input[type="checkbox"]')) {
        if ($element.prop('checked')) {
          $target.attr(config.key, $element.val());
        }
      }
      else {
        $target.attr(config.key, $element.val());
      }
    });

    $container.find('[data-trackable]').trigger('change');
  };

  return module;

})();
