if ((typeof tinymce === 'undefined') || (tinymce === null)) {
  tinymce = null;
}

$(document).ready(function ($, HBMLG, ClipboardJS) {
  'use strict';

  // Works in combination with AjaxAuthenticationListener.php
  $(document).ajaxError(function (event, jqXHR) {
    if ((jqXHR.status === 401) || (jqXHR.status === 403)) {
      if (confirm('Sie sind nicht mehr eingeloggt. Soll die Seite neu geladen werden?')) {
        window.location.reload();
      }
    }
  });

  var $body = $('body');

  HBMLG.initAjaxLinks($body);
  HBMLG.initAjaxButtons($body);
  // HBMLG.initAjaxSecured($body, $('#ajax-secured-toggle'));
  HBMLG.handleAjaxSecured(false, $body, $('#ajax-secured-toggle'));
  HBMLG.initDateTimePicker($body);
  HBMLG.initToggablePasswords();
  HBMLG.initClipboards();
  HBMLG.initTrackables($body);

  $('[data-card-collapsible]').hbm_initCollapsibleCards();
  $body.hbm_initToggables();

  $('[data-toggle="tooltip"]').tooltip();

  $('.copy-to-clipboard').tooltip({
    title: function () {
      return this.dataset.title;
    }
  });

  var clipboard = new ClipboardJS('.copy-to-clipboard');

  clipboard.on('success', function (e) {
    var title = $(e.trigger).attr('data-title');

    $(e.trigger).attr('data-title', 'Kopiert!').tooltip('show');

    window.setTimeout(function () {
      $(e.trigger).tooltip('hide').attr('data-title', title);
    }, 1000);
  });

  var clipboardBatch = new ClipboardJS('.copy-to-clipboard-batch', {
    text: function (trigger) {
      var targetSelector = $(trigger).data('clipboard-targets-selector');
      var seperator = '-------------';
      var copyString = seperator + '\n';
      var targets = document.querySelectorAll('[data-' + targetSelector + ']');
      var qtd = targets.length;
      for (var i = 0; i < qtd; i++) {
        copyString += $(targets[i]).data(targetSelector) + seperator + '\n';
      }
      return copyString;
    }
  });

  clipboardBatch.on('success', function (e) {
    var title = $(e.trigger).attr('data-title');

    $(e.trigger).attr('data-title', 'Kopiert!').tooltip('show');

    window.setTimeout(function () {
      $(e.trigger).tooltip('hide').attr('data-title', title);
    }, 1000);
  });

  $('.mark-all-winner').on('click', function () {
    var targets = document.querySelectorAll('a[data-mark-winner-batch="true"]');

    if (targets.length > 0) {
      targets.forEach(function (element) {
        element.click();
      });
    }
  });

  $('.handle-dirty').dirty({
    preventLeaving: true
  });

  $.fn.select2.defaults.set('theme', 'bootstrap4');
  $.fn.select2.defaults.set('width', '100%');
  // Disable deletion with backspace.
  // See: https://github.com/select2/select2/issues/3354
  $.fn.select2.amd.require(['select2/selection/search'], function (Search) {
    Search.prototype.searchRemoveChoice = function (decorated, item) {
      return;
    };
  }, null, true);

  $('select.select2').select2({});

  $('.js-tooltip').tooltip({
    title: function () {
      return this.dataset.title;
    }
  });

  if (tinymce) {
    tinymce.init({
      plugins: 'link table fullscreen code lists',
      skin: 'tinymce-5',
      selector: '.tinymce',
      menubar: false,
      license_key: 'gpl',
      toolbar: [
        'undo redo | styles | link unlink | table | removeformat | code | fullscreen',
        'bold italic strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | blockquote hr'
      ]
    });
  }

}(jQuery, HBMLG, ClipboardJS, tinymce));
